import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page, CalloutCard, List, Layout, Card } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';

class Version extends React.Component {

    state = {
        loading: false
    }

    componentDidMount = async () => {
        if (this.props.shop.info.version == process.env.REACT_APP_VERSION) {
            this.props.history.push("/admin/");
        }
    }

    updateVersion = async() => {
        let rUrl = 'https://quick-pay.boxup.io/admin/update';
        let url = `https://${this.props.shop.info.shop}/admin/oauth/request_grant?client_id=` +
            `${process.env.REACT_APP_API_KEY}&scope=${process.env.REACT_APP_SCOPE}&redirect_uri=${rUrl}`;
        window.top.location.href = url;
    }

    render() {
        let { loading } = this.state;
        if (loading) {
            return <Loading/>
        } else {
            
            return <Page
                title="We've got an update for you!"
                subtitle="Our update requires extra permissions from you."
            >
                <Layout>
                    <Layout.Section>
                        <Card title="So, what's new?" sectioned>
                        <p style={{marginBottom: '20px'}}>
                            The new permissions are required to allow these new features:
                        </p>
                        <List>
                            <List.Item>
                                (Optional per builder) - Once an order has been paid, we'll amend it by adding each variant included in the box, so your stock is 
                                appropriately adjusted and any downstream systems relying on order information will be fed the correct variant(s) details.
                            </List.Item>
                        </List>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <CalloutCard
                            title=""
                            illustration="/assets/image/new.png"
                            primaryAction={{
                                content: 'Update Box Builder',
                                primary: true,
                                onAction: () => this.updateVersion()
                            }}
                            >
                            <p>
                                We will direct you over to Shopify to grant the new permission, you'll then come right back to our app.<br/> 
                                It will just take a few seconds.
                            </p>
                        </CalloutCard>
                    </Layout.Section>
                </Layout>
            </Page>
        }
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(Version));