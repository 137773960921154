import React, { useEffect } from 'react';

export const LoadingPage = ({shop}) => {

    useEffect(() => {
        let element = window.document.getElementById("loading-box");
        window.lottie.loadAnimation({
            container: element, 
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/assets/image/loading-box.json' // the path to the animation json
        });
    },[]);

    return (
        <div style={{backgroundColor:'#f4f6f8',height:'100%',margin:'auto',width:'100%',position:'absolute', justifyContent: 'center', textAlign: 'center'}}>
            <div id="loading-box" style={{height: '500px'}}></div>
        </div>
    )
}