import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyDPqXHFCWnLBrOuvoOiIX2XkngjmnAz_xg",
    authDomain: "quick-pay-boxup.firebaseapp.com",
    projectId: "quick-pay-boxup",
    storageBucket: "quick-pay-boxup.appspot.com",
    messagingSenderId: "341310259206",
    appId: "1:341310259206:web:f86457c360f7d017550387",
    measurementId: "G-TKVVJ11NE1"
};

export const fb = !app.apps.length ? app.initializeApp(config) : app.app();
export const db = app.firestore();
export const storage = app.storage();

export const translations = [
    {
        key: 'checkoutTitle',
        default: 'Checkout',
        label: 'Title that appears at the top of the checkout modal.'
    }
];