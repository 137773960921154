import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import { fb } from './../../actions/settings';
import { LoadingPage } from './../loading';
import ErrorPage from './../auth/error';
import { confirmPlan, login } from './../../actions/';

class Paid extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: ''
        }
    }

    componentDidMount = async () => {
        try {
            let res = await confirmPlan(this.queryParams);
            if (res.error) {
                this.setState({
                    loading: false,
                    error: res.message
                });
            } else {
                let user = await fb.auth()
                    .signInWithEmailAndPassword(res.user.email, res.unique);
                if (user) {
                    this.props.login(res.user);
                    this.props.history.push("/admin/");
                }
            }
        } catch(e) {
            console.log(e);
            this.setState({
                loading: false,
                error: e.message
            });
        }
    }

    render() {
        let { loading } = this.state;
        if (loading) {
            return <LoadingPage  shop=""/>;
        } else {
            return <ErrorPage/>;
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Paid);