import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, TextContainer, Page } from '@shopify/polaris';

export const Support = () => {

    const questions = [
        {
            title: 'One or more products are not showing up on my builder',
            answer: 'Products with no collections will not show as our app has to locate them using collections. ' +
                'If you have products like this, simply create a collection and attach all products. The collection you create can be named anything you like.'
        },
        {
            title: 'How can I contact you?',
            answer: 'Our live chat will be available at the bottom-right of the screen within office hours. Please send us an email to support@boxup.io if that is not available'
        },
        {
            title: 'Can I see a demo?',
            answer: 'Of course! You can see both styles by visiting our ' + 
                '<a href="https://boxup-builder.myshopify.com/apps/builder?b=FHzQWxZfe2F1MVSzg0BEXr0b" target="_blank">demo store</a> ' +
                'and using the links in the main menu.'
        },
        {
            title: 'How do I set the price the customer pays for each build?',
            answer: 'The price comes from all the products/variants that the customer ends up selecting throughout each step. ' + 
                'As they add more products, the price will increase based on the prices you set for each variant.'
        },
        {
            title: 'How do I get customers to see my builder?',
            answer: 'Once you have set up your builder. You will be given a link (URL) to preview it. You can use this link wherever you would like. ' + 
                'For example, you can add a menu item in the Online Store > Navigation page.'
        }
    ];

    return <Page
            separator
        >
        <Card sectioned title="Frequently Asked Questions">
            {questions.map((question, i) => {
                return <Card.Section 
                    key={i}
                    title={
                    <span href="#this" style={{fontSize: '1.6rem', fontWeight: 'bold'}}>
                        {question.title}
                    </span>
                    }
                >
                    <TextContainer>
                        <p dangerouslySetInnerHTML={{__html: question.answer}}></p>
                    </TextContainer>
                </Card.Section>
            })}
        </Card>
    </Page>
}


const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(Support));