import React from 'react';
import { AppProvider, EmptyState } from '@shopify/polaris';

export default class ErrorPage extends React.Component {
    componentDidMount() {
        window.lottie.loadAnimation({
            wrapper: document.getElementById("img"),
            animType: 'svg',
            loop: true,
            path: '/assets/animations/error.json'
        });
    }

    render() {
        return <>
            <div style={{
                backgroundColor:'#f4f6f8',
                marginLeft: 10+'%',
                width: 80+'%'
            }}>
                <div style={{
                    paddingTop: 80+'px'
                }}>
                    <AppProvider 
                        apiKey={process.env.API_KEY}
                        forceRedirect={process.env.FORCE_REDIRECT}
                    >
                        <EmptyState 
                            heading="Whoops!"
                            action={{ content: 'App Home', url: '/admin/' }} 
                            secondaryAction={{ content: 'BoxUp Support', url: 'https://boxup.io/contact' }}
                            image="/assets/image/error.svg" 
                            imageContained={true}
                            footerContent={
                                <p>
                                  If you struggle to load the app, please reload it from your Shopify admin.
                                </p>
                            }
                        >
                            <p>Something seems to have gone wrong.</p>
                        </EmptyState>
                    </AppProvider>
                </div>
            </div>
        </>
    }
}