import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from './../../actions/settings';
import { login, setPath, cleanReducer } from './../../actions/';
import { Page, Layout, Card, TextStyle, SettingToggle, Heading } from '@shopify/polaris';
import { Loading } from './loading';

const HomeComponent = (props) => {

    const [loading, setLoading] = useState(true);
    const [subLoading, setSubLoading] = useState(false);
    const [storeActive, setStoreActive] = useState(false);

    const handleToggle = async() => {
        setSubLoading(true);
        await db.collection("Shops").doc(props.shop.info.id).update({storeActive: !storeActive});
        setStoreActive(!storeActive);
        setSubLoading(false);
    };

    useEffect(() => {
        if (props.shop.info.version == process.env.REACT_APP_VERSION) {
            props.setPath("Dashboard");
        } else {
            props.history.push("/admin/version");
        }
        if (window.$zoho.salesiq.visitor) 
            window.$zoho.salesiq.visitor.email(props.shop.info.shop || '');
        setLoading(false);
    }, []);

    if (loading) {
        return <Loading/>
    } else {
        return <Page
            title="BoxUp's Quick Pay"
            subtitle="Sell anywhere, quicker."
            separator
        >
            <Layout>
                <Layout.Section oneHalf>
                    <SettingToggle
                        action={{
                            content: storeActive ? 'Deactivate' : 'Activate',
                            onAction: handleToggle,
                            loading: subLoading
                        }}
                        enabled={storeActive}
                        >
                            <Heading element="h1">Online Store</Heading>
                            Show a Quick Pay button on your store's product page.
                    </SettingToggle>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <SettingToggle
                        action={{
                            content: 'Create a Button',
                            onAction: handleToggle,
                        }}
                        enabled={false}
                        >
                            <Heading element="h1">Sell Anywhere Else</Heading>
                            Create HTML code that can be added to anywhere.
                    </SettingToggle>
                </Layout.Section>
            </Layout>
        </Page>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login,
    setPath,
    cleanReducer
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HomeComponent));