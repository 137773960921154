import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import { LoadingPage } from './../../screens/loading';
import ErrorPage from './../auth/error';
import { install, login } from './../../actions/';
import { fb } from './../../actions/settings';

class Install extends ReactQueryParams {

    state = {
        loading: true,
        error: false
    }

    componentDidMount() {
        install(this.queryParams)
        .then(async(res) => {
            console.log(res);
            if (res.error) {
                this.setState({
                    loading: false,
                    error: true
                });
            } else {
                if (res.developer) {
                    let user = await fb.auth()
                        .signInWithEmailAndPassword(res.user.email, res.unique);
                    if (user) {
                        this.props.login(res.user);
                        this.props.history.push("/admin/");
                    }
                } else {
                    window.top.location.href = res.charge_url;
                }
            }
        })
        .catch((err) => console.log(err));
    }

    render() {
        let { loading } = this.state;
        if (loading) {
            return <LoadingPage shop=""/>;
        } else {
            return <ErrorPage title="Error installing!" message="Please go back to Shopify and try again."/>;
        }
    }
}

const mapDispatchToProps = {
    login
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Install);