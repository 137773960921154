
const defaultState = {
    info: null,
    loggedIn: false,
    path: 'Dashboard'
}

export default function shop (state = defaultState, action) {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                loggedIn: true,
                info: action.payload.info
            }
        case "LOGOUT":
            return {
                ...state,
                info: null,
                loggedIn: false
            }
        case "SET_PATH":
                return {
                    ...state,
                    path: action.payload
                }
        default:
            return state;
    }
}