import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateVersion, login } from './../../actions/';
import { db } from './../../actions/settings';
import { LoadingPage } from './../../screens/loading';
import { Page } from '@shopify/app-bridge-react';

class UpdateVersion extends React.Component {

    state = {
        loading: true,
        error: false
    }

    componentDidMount = async () => {
        if (this.props.shop.info.version == process.env.REACT_APP_VERSION) {
            this.props.history.push("/admin/");
        } else {
            //step 2 = webhooks
            // let res = await updateVersion("two", this.props.shop.info.id);
            // if (res.error) {
            //     this.setState({loading: false, error: true});
            // } else {
                await db.collection("Shops").doc(this.props.shop.info.id).update({version: process.env.REACT_APP_VERSION});
                this.props.login({...this.props.shop.info, version: process.env.REACT_APP_VERSION});
                this.props.history.push("/admin/");
            //}
        }
    }

    render() {
        let { error, loading } = this.state;
        if (loading) {
            return <LoadingPage shop=""/>;
        } else if (error) {
            return <Page
                title="An error occurred."
                subtitle="Please open a chat with our support to assist (bottom right corner)."
            >
            </Page>
        } else {
            return <LoadingPage shop=""/>;
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UpdateVersion));