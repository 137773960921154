export function login(info) {
    return {
        type: "LOGIN",
        payload: {
            info
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: {
            info: null
        }
    }
}

export function setStep(step) {
    return {
        type: "NEXT_STEP",
        payload: {
            currentStep: step
        }
    }
}

export function saveDiscounts(discounts) {
    return new Promise((resolve) => {
        resolve({
            type: "SAVE_DISCOUNTS",
            payload: discounts
        });
    });
}

export function cleanReducer() {
    return new Promise((resolve) => {
        resolve({
            type: "CLEAN_BUILDER"
        });
    });
}

export function setPath(path) {
    return {
        type: "SET_PATH",
        payload: path
    }
}

// normal functions

const API_URL = process.env.REACT_APP_API_URL;

export function checkAuth(query) {
    return new Promise((resolve, reject) => {
        let parameters = [];
        for (var key in query) {
            parameters.push(key + '=' + query[key]);
        }

        let params = parameters.sort().join(query.hmac ? '&' : '');
        const endpoint = `${API_URL}/auth?${params}`;
    
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function install(query) {
    return new Promise((resolve, reject) => {
        const { timestamp, shop, hmac, code, state, host } = query;
        let endpoint = `${API_URL}/auth/install?shop=${shop}&hmac=${hmac}&timestamp=${timestamp}&code=${code}&state=${state}`;
        if (host) endpoint = endpoint + `&host=${host}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function checkPlan(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/auth/plan?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function confirmPlan(query) {
    return new Promise((resolve, reject) => {
        const { charge_id, id } = query;
        const endpoint = `${API_URL}/auth/confirm?id=${id}&charge_id=${charge_id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function storeScriptTag(id, enabled) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/script?id=${id}&enabled=${enabled}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function updateVersion(version, id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/versions/${version}`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "id": id
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}