import React from 'react';
import { SkeletonPage, Layout, SkeletonBodyText, SkeletonDisplayText, AccountConnection } from '@shopify/polaris';

export const Loading = () => {
    return <SkeletonPage>
        <Layout>
            <Layout.AnnotatedSection
                title={<SkeletonDisplayText size="small" />}
                description={<SkeletonBodyText />}
            >
                <AccountConnection
                    avatarUrl={"/assets/image/store.png"}
                    connected={false}
                    title={<SkeletonDisplayText size="small" />}
                    action=""
                    details={<SkeletonBodyText lines={2} />}
                    termsOfService={<SkeletonBodyText lines={2} />}
                />
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title={<SkeletonDisplayText size="small" />}
                description={<SkeletonBodyText />}
            >
                <AccountConnection
                    avatarUrl={"/assets/image/store.png"}
                    connected={false}
                    title={<SkeletonDisplayText size="small" />}
                    action=""
                    details={<SkeletonBodyText lines={2} />}
                    termsOfService={<SkeletonBodyText lines={2} />}
                />
            </Layout.AnnotatedSection>
        </Layout>
    </SkeletonPage>
}