import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import createApp from '@shopify/app-bridge';
import { Button, TitleBar } from '@shopify/app-bridge/actions';
import { setPath } from './../actions/';

function Layout(props) {

    const createToolbar = () => {
        const app = createApp({
            apiKey: process.env.REACT_APP_API_KEY,
            shopOrigin: props.shop.info.shop,
        });
        const homeButton = Button.create(app, { label: 'My Settings' });
        homeButton.subscribe('click', () => {
            props.setPath("My Settings");
            props.history.push("/admin/");
        });
        const suppButton = Button.create(app, { label: 'Support' });
        suppButton.subscribe('click', () => {
            props.setPath("Support");
            props.history.push("/admin/support");
        });
        const titleBarOptions = {
            title: props.shop.path,
            buttons: {
                secondary: [homeButton, suppButton]
            },
        };
        TitleBar.create(app, titleBarOptions);
        if (window.$zoho.salesiq.floatbutton)
            window.$zoho.salesiq.floatbutton.coin.hidetooltip();
    }

    useEffect(() => {
        createToolbar();
    }, [props.shop.path])

    return (
        <div>
            {props.children}
        </div>
    )
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    setPath
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Layout));